import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Thanks for reaching out to me and my team! We'll get to you as soon as we can.`}</p>
    <p>{`While you wait, have you seen `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`my blog`}</a>{`?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      